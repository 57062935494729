<template>
  <CCard id="fortschrittsanzeige">
    <CCardBody>
      <CRow class="align-items-center">
        <CCol sm="3" class="text-center gauge">
          <CChartPie
            :datasets="defaultDatasets"
            :options="chartOptions"
          />
        </CCol>
        <CCol sm="6" class="text-center text">
          <h3>Fortschritt in der aktuellen Kalenderwoche</h3>
          {{istStunden|number('0.00')}} von {{sollStunden|number('0.00')}} Arbeitsstunden sind bereits geleistet.
        </CCol>
        <CCol sm="3" class="text-center icon">
          <CIcon name="cil-coffee" size="9xl"/>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'component-fortschritt',
  props: {
    datum: {
      default: '',
      type: [Date, String]
    }
  },
  created () {
    this.$eventBus.$on('reloadtageskalender', (data) => {
      this.convertProps()
      this.loadData()
    })
  },
  mounted () {
    this.convertProps()
    this.loadData()
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    },
    sollStunden () {
      return this.user.sollstunden
    },
    fehlendeStunden () {
      return this.sollStunden - this.istStunden
    },
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#f9b115',
            '#3399ff'
          ],
          data: [
            this.istStunden,
            this.fehlendeStunden
          ]
        }
      ]
    },
    chartOptions () {
      return {
        borderWidth: 0,
        legend: {
          display: false
        }
      }
    }
  },
  data () {
    return {
      localDate: '',
      istStunden: 0
    }
  },
  watch: {
    datum: function () {
      this.convertProps()
      this.loadData()
    }
  },
  methods: {
    convertProps: function () {
      if (this.datum === '') {
        this.localDate = moment().format('YYYY-MM-DD')
      }
      if (this.datum instanceof Date) {
        this.localDate = moment(this.datum).format('YYYY-MM-DD')
      }
    },
    loadData: function () {
      const week = moment(this.localDate).week()
      const year = moment(this.localDate).year()
      if (year && week) {
        Vue.axios.get('/tageskalender/wochenstunden/' + year + '/' + week)
          .then((response) => {
            this.istStunden = response.data
          })
      }
    }
  }
}
</script>
