<template>
  <CRow>
    <CCol sm="12">

      <Fortschritt :datum="datum"/>

      <Kalenderwoche v-model="datum" />

      <!-- Suche v-model="suchwort"/-->

      <Summe :datum="datum"/>
      <Suche v-model="rechnungsnummer"/>
      <Formular :datum="datum"/>
    </CCol>
  </CRow>
</template>

<script>
import moment from 'moment'
import Fortschritt from '@/components/Fortschritt'
import Kalenderwoche from '@/views/Tageskalender/components/Kalenderwoche'
import Suche from '@/views/Tageskalender/components/Suche'
import Summe from '@/views/Tageskalender/components/Summe'
import Formular from '@/views/Tageskalender/components/Formular'

export default {
  name: 'tageskalender-index',
  components: {
    Fortschritt,
    Kalenderwoche,
    Suche,
    Summe,
    Formular
  },
  props: {
    pdatum: String
  },
  data () {
    return {
      datum: new Date(),
      rechnungsnummer: '',
      suchwort: ''
    }
  },
  mounted () {
    if (this.pdatum) {
      const ldatum = moment(this.pdatum).toDate()
      this.datum = ldatum
    }
  }
}
</script>
