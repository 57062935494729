<template>
  <CRow class="form-group">
    <CCol sm="12">
      <label :class="formLabelClass">{{formLabel}}</label>
      <CSelect
        :value.sync="filterValue"
        :options="janein"
        :isValid="true"
        v-on:update:value="inputEvent"
      >
      </CSelect>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'FormBoolean',
  props: {
    formLabel: {
      default: 'Text',
      type: String
    },
    formLabelClass: {
      default: '',
      type: String
    },
    value: Boolean
  },
  data () {
    return {
      janein: [
        {
          value: 'true',
          label: 'Ja'
        },
        {
          value: 'false',
          label: 'Nein'
        }
      ],
      filterValue: 'false',
      isValid: false
    }
  },
  mounted () {
    if (this.value) {
      this.filterValue = 'true'
    } else {
      this.filterValue = 'false'
    }
  },
  watch: {
    value: function () {
      if (this.value) {
        this.filterValue = 'true'
      } else {
        this.filterValue = 'false'
      }
    }
  },
  methods: {
    inputEvent: function (newValue) {
      if (newValue === 'true') {
        this.$emit('input', true)
        return true
      } else {
        this.$emit('input', false)
      }
    }
  }
}
</script>
